import React, { useState, useEffect } from 'react';
import './App.css';
import './fonts/Exo/Exo.css'; 
import logo from './images/barty_logo.png';
function ComingSoonPage() {
  const [launchDate] = useState(new Date('2023-12-25T00:00:00Z'));
  const [countdown, setCountdown] = useState('');

  useEffect(() => {
    const interval = setInterval(updateCountdown, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const updateCountdown = () => {
    const now = new Date();
    const distance = launchDate - now;

    if (distance < 0) {
      setCountdown('Website is live!');
    } else {
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      setCountdown(`${days}d ${hours}h ${minutes}m ${seconds}s`);
    }
  };

  return (
    <div style={{ backgroundColor: '#111111', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <div style={{ color: '#ffffff', textAlign: 'center' }}>
        <div className="logo-container">
          <img src={logo} alt="Logo" className="logo" />
          <h1 style={{ fontSize: '48px', marginBottom: '24px' }}>Barty</h1>
        </div>
        <p style={{ fontSize: '24px', marginBottom: '16px' }}>The future of personalized travel experiences</p>
        <p style={{ fontSize: '18px', marginBottom: '24px' }}>Launching on December 25th, 2023</p>
        <div className="countdown" style={{ fontSize: '36px' }}>{countdown}</div>
      </div>
    </div>
  );
}

function App() {
  const [showComingSoon, setShowComingSoon] = useState(true);

  useEffect(() => {
    const launchDate = new Date('2023-09-25T00:00:00Z');
    const now = new Date();
    const showComingSoon = now < launchDate;
    setShowComingSoon(showComingSoon);
  }, []);

  return (
    <div>
      {showComingSoon ? (
        <ComingSoonPage />
      ) : (
        // Render your main application here
        // ...
        // Example:
        <h1>Welcome to Your App</h1>
      )}
    </div>
  );
}

export default App;
